import store from '@/store/index.js'

/*
export function checkPageWidget(item) {
    return () => import(`@/views/Dashboard/PageWidgets/${item.meta.pageWidget}.vue`)
        .then(module => {
            return module
        })
        .catch(e => {
            console.log('error')
            return import(`@/views/Dashboard/PageWidgets/NotPageWidget.vue`)
        })
}
*/

export const dummyRoutes = [
    {
        name: 'ru_dummy',
        path: 'ru',
        children: [
            {
                name: 'notifications_dummy',
                path: 'notifications'
            }
        ]
    }
]

export function checkNewPageWidget(route) {
    return () => import(/* webpackMode: "lazy" */`@/views/Dashboard/PageWidgets/${route.pageWidget}.vue`)
        .then(module => {
            return module
        })
        .catch(e => {
            console.log(e, 'error')
            return import(`@/views/Dashboard/PageWidgets/NotPageWidget.vue`)
        })
}

export const initRouteInfo = async ({to, from, init, prototype}) => {
    if(from.name !== to.name && to.name !== 'profile' && to.name !== 'menu' && to.name !== 'create_order' && to.name !== 'create_return_order' && to.name !== 'chat-body' && to.name !== 'chat-contact') {
        try {
            prototype.$Progress.start()
            await store.dispatch('navigation/getRouterInfo', { name: to.name })
        } catch(e) {
            prototype.$Progress.fail()
            console.log(e, 'initRouteInfo')
        } finally {
            prototype.$Progress.finish()
        }
    }
}

export const queryCheck = to => {
    const checkList = ['token', 'join', 'meeting', 'task', 'event', 'sprint', 'viewGroup', 'viewProject', 'viewGoods', 'my_profile', 'menu_page', 'stab', 'tab', 'chat_id']
    const query = {}
    checkList.forEach(item => {
        if(to.query?.[item]) {
            query[item] = to.query[item]
        }
    })
    return query
}

export function findName(array, name) {
    return array.some((object) => object.children ? findName(object.children, name) : object.name === name)
}

export const findFront = routes => {
    const filtered = routes?.filter(f => f.isShowMobile && !f.hideMobile)
    if (filtered?.length) {
        return filtered[0].name
    } else
        return ''
}